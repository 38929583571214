import React from "react"

import Layout from "../../../components/layout-static"
import Seo from "../../../components/seo"

const Page = () => {
  const metadata = {
    title: "Attorney Case Referral",
    description:
      "Trust Bartlett & Grippe, LLC, a proven personal injury firm. With national experience and referrals, we handlc cases at any litigation level and location. Contact us today.",
    heroH1: "Attorney Case Referral",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>We accept Personal Injury and Wrongful Death cases</h2>
            <p className="mb-8">
              A significant percentage of our cases at Bartlett & Grippe, LLC
              have been a referral to us by other attorneys who are familiar
              with our results, reputation and experience, both in and out of
              the courtroom. I am always happy to speak with attorneys and
              discuss their personal injury cases to determine if our office can
              assist in, or take over the handling of their matters at any level
              of litigation, from accident to litigation to appeal to
              post-judgment proceedings. Your client need not reside in
              Connecticut. We have successfully handled Connecticut cases for
              clients and attorneys across the country, having received
              referrals of clients residing as far away as Florida, Oregon,
              California, and Alaska.
            </p>
            <p className="mb-8">
              We thank all the attorneys and past clients that have put their
              faith in us by referring clients, friends, and relatives to our
              firm, and we hope to continue to build more of these valued
              relationships in the years to come. I welcome attorneys or friends
              to contact me directly by phone or email to initiate discussions.
            </p>
          </div>
          <p>Frank C. Bartlett Jr.</p>
          <p>Managing Partner</p>
        </div>
      </div>
    </Layout>
  )
}

export default Page
